import React, { Fragment } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';

import SEO from '../components/seo';

import styled from 'styled-components';
import tw from 'twin.macro';

// import ArticleHeader from '../components/Blog/ArticleHeader';
import LargeImage from '../components/Blog/LargeImage';
import LeftImage from '../components/Blog/LeftImage';
import RightImage from '../components/Blog/RightImage';

import BackgroundImage from 'gatsby-background-image';

import SafeAreaWrapper from '../components/SafeAreaWrapper';
import Banner from '../components/Banner';

import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import BannerImage from '../images/banner-screenshot.png';
import { GatsbyPrismicImageFixed } from '../../.cache/fragments/gatsby-source-prismic-fragments';

import { RichText } from 'prismic-reactjs';

// const Header = props => <h3 className="container mx-auto" {...props} />;
const BlockQuote = props => (
	<blockquote className="container mx-auto font-medium my-50 md:my-60 xl:my-80" {...props} />
);

const Highlight = props => <section className="bg-porcelain my-120 py-120" {...props} />;

// Use TailwindCSS + Styled Components to style sibling elements
// - mb-16 to headers and paragraphs with a paragraph as their next sibling
// - headers which are root elements doesn't have a top margin mt-40

// h + p: px-20 xl:px-240

const Header1 = styled.h1`
	${tw`container mx-auto`}

	& + p {
		${tw`mt-20`}
	}
`;

const Content = styled.div`
	${tw`container mx-auto`}

	h1, h2, h3, h4, h5, h6 {
		${tw`mb-20`}
	}

	p {
	}

	p + p {
		${tw`mt-16`}
	}
`;

const shortcodes = {
	blockquote: BlockQuote,
	// Header,
	h1: Header1,
	// p: props => <Paragraph {...props} />,
	Link,
	Img,
	LargeImage,
	LeftImage,
	RightImage,
	Highlight,
};

const product = ({ data }) => {
	const document = data.prismicProduct.data;
	if (!document) return null;

	const featureImage = document.hero !== null ? document.hero.fixed : null;

	// var images = {};
	// if (data.mdx.frontmatter.images) {
	// 	data.mdx.frontmatter.images.forEach((image, index) => {
	// 		images[`Image${index}`] = () => <Img fluid={image.childImageSharp.fluid} />;
	// 	});
	// }

	const name = document.name.text;
	const description = document.description.text;

	const content = document.body.map(function (slice, index) {
		switch (slice.slice_type) {
			case 'text':
				return (
					<div className="container mx-auto py-60 grid grid-cols-12">
						<Content
							className="col-start-3 col-end-10"
							dangerouslySetInnerHTML={{ __html: slice.primary.text.html }}
						/>
					</div>
				);
				break;

			case 'key_feature':
				return (
					<div className="container mx-auto py-60 flex flex-row content-between -px-20">
						{slice.items.map((keyFeature, index) => (
							<div className="px-20">
								<div className="flex justify-center mx-30 mb-30">
									<img src={keyFeature.icon.url} alt={keyFeature.icon.alt} />
								</div>
								<h3 className="text-center">{keyFeature.title.text}</h3>
								<Content
									className="text-center"
									dangerouslySetInnerHTML={{ __html: keyFeature.text.html }}
								/>
							</div>
						))}
					</div>
				);
				break;

			case 'feature':
				// even:bg-porcelain

				const content = slice.items.map((feature, index) => (
					<div className={`${index % 2 == 0 ? 'bg-porcelain' : ''}`}>
						<div
							className={`container mx-auto flex ${
								index % 2 == 0 ? 'flex-row' : 'flex-row-reverse'
							}`}>
							<div className="w-1/2 grid grid-cols-6">
								<div className="col-start-2 col-span-4 flex flex-col justify-center">
									<Content
										className="my-120"
										dangerouslySetInnerHTML={{
											__html: feature.text.html,
										}}
									/>
								</div>
							</div>
							<div className="w-1/2">
								<Img
									fluid={feature.image.fluid}
									alt={feature.image.alt}
									className={`${feature.image_casts_shadow ? 'shadow-xl' : ''} ${
										feature.image_vertical_alignment == 'Center'
											? 'my-120'
											: feature.image_vertical_alignment == 'Top'
											? 'mb-120'
											: 'mt-120'
									}`}
								/>
							</div>
						</div>
					</div>
				));

				return <section className="my-60">{content}</section>;

				break;
			default:
				return null;
		}

		// if (slice.slice_type === 'text') {
		// 	return RichText.render(slice.primary.rich_text, linkResolver);

		// 	// Image Gallery Slice
		// } else if (slice.slice_type === 'image_gallery') {
		// 	const galleryContent = slice.items.map(function (image, imageIndex) {
		// 		return (
		// 			<img
		// 				src={image.gallery_image.url}
		// 				alt={image.gallery_image.alt}
		// 				key={imageIndex}
		// 			/>
		// 		);
		// 	});
		// 	return (
		// 		<div className="image-gallery" key={index}>
		// 			<h2 className="gallery-title">
		// 				{RichText.asText(slice.primary.gallery_title)}
		// 			</h2>
		// 			{galleryContent}
		// 		</div>
		// 	);

		// 	// Return null by default
		// } else {
		// 	return null;
		// }
	});

	return (
		<Layout translucent={true}>
			<SEO title={name} description={description} />
			<BackgroundImage
				Tag="section"
				className="bg-cover bg-center h-500 md:h-550 xl:h-600 flex items-end"
				fluid={data.blueBackgroundImage.childImageSharp.fluid}
				backgroundColor={`rgba(0, 70, 121, .6)`}>
				<div className="container h-500 -mb-60">
					<div className="grid grid-rows-1 grid-cols-12 gap-20 h-500">
						<div className="col-start-2 col-span-3 flex flex-col justify-center h-full">
							<div className="text-center mr-40 mb-120 xl:text-left">
								<h1 className="text-white">{name}</h1>
								<p className="text-white mt-20">{description}</p>
							</div>
						</div>
						<div className="col-span-8 flex flex-col justify-end h-full relative">
							<div className="absolute right-0">
								<Img fixed={featureImage} alt={name} className="rounded" />
							</div>
						</div>
					</div>
				</div>
			</BackgroundImage>

			<main className="my-120">
				{content}
				<Banner
					title="Experience our easy to use ship management solution"
					subtitle="Get to know us"
					image={BannerImage}
				/>
			</main>
		</Layout>
	);
};

export default product;

export const pageQuery = graphql`
	query ProductQuery($id: String!) {
		prismicProduct(id: { eq: $id }) {
			uid
			data {
				name {
					text
				}
				description {
					text
				}
				hero {
					fixed(width: 765, height: 493) {
						...GatsbyPrismicImageFixed
					}
				}
				body {
					... on PrismicProductBodyFeature {
						slice_type
						items {
							text {
								html
							}
							image_casts_shadow
							image_vertical_alignment
							image {
								alt
								fluid {
									...GatsbyPrismicImageFluid
								}
							}
						}
					}
					... on PrismicProductBodyKeyFeature {
						slice_type
						items {
							title {
								text
							}
							text {
								html
							}
							icon {
								alt
								url
							}
						}
					}
					... on PrismicProductBodyText {
						slice_type
						primary {
							text {
								html
							}
						}
					}
				}
			}
		}

		blueBackgroundImage: file(relativePath: { eq: "blue-background.png" }) {
			childImageSharp {
				fluid(quality: 60, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		bannerImage: file(relativePath: { eq: "banner-screenshot.png" }) {
			childImageSharp {
				fluid(quality: 60) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;
